const merchant = [
  {
    path: '/merchant/overview',
    name: 'merchant-view',
    component: () => import('@/modules/merchant/views/merchant-view/MerchantView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default merchant
